import { forwardRef, InputHTMLAttributes } from "react";
import TextInput, { DesignType } from "../atoms/TextInput";
import InputLabel from "../atoms/InputLabel";

export default forwardRef(function TextForm(
  {
    label,
    className = "",
    ...props
  }: InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    designType?: DesignType;
  },
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const formId = crypto.randomUUID();
  return (
    <div>
      {label && <InputLabel htmlFor={formId} value={label} />}

      <TextInput
        {...props}
        id={formId}
        className={"block w-full " + className}
        ref={ref}
      />
    </div>
  );
});
