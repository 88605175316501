import {
  forwardRef,
  // useEffect,
  // useImperativeHandle,
  // useRef,
  InputHTMLAttributes,
} from "react";

export type DesignType = "standard" | "borderLess";

export default forwardRef(function TextInput(
  {
    type = "text",
    className = "",
    // isFocused = false,
    designType = "standard",
    ...props
  }: InputHTMLAttributes<HTMLInputElement> & {
    isFocused?: boolean;
    designType?: DesignType;
  },
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  // const localRef = useRef<HTMLInputElement>(null);

  // useImperativeHandle(ref, () => ({
  //   focus: () => localRef.current?.focus(),
  // }));

  // useEffect(() => {
  //   if (isFocused) {
  //     localRef.current?.focus();
  //   }
  // }, []);

  const baseClass =
    designType === "borderLess"
      ? "peer py-3 pe-0 ps-8 block w-full bg-transparent border-t-transparent border-b-2 border-x-transparent border-b-gray-200 text-sm focus:border-t-transparent focus:border-x-transparent focus:border-b-blue-500 focus:ring-0 disabled:opacity-50 disabled:pointer-events-none dark:border-b-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 dark:focus:border-b-gray-600 "
      : "py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ";

  return (
    <input
      {...props}
      // id={props.name}
      type={type}
      className={baseClass + className}
      ref={ref}
    />
  );
});
